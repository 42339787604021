<template>
  <div>
    <SelectBriefingLanguageComponent
      @handleChangeLanguage="(e) => (selectedLanguage = e)"
    />
    <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("company_presentation") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <span>{{ $t("presentation_hint") }}</span>
            <div class="mt-1 mb-4">
              <input
                id="imageSlideInput"
                name="imageSlideInput"
                ref="imageSlideInput"
                type="file"
                multiple
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                @change="setPresentationZip"
              />
            </div>
            <v-checkbox
              v-if="company.Presentation != null"
              class="my-0 py-0"
              v-model="company.DeletePresentation"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{
                  $t("delete_presentation")
                }}</span>
              </template></v-checkbox
            >
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-1" v-if="!roundBriefing">
          <v-expansion-panel-header>
            {{ $t("company_info") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content
            ><v-text-field
              :label="$t('funding_target')"
              type="number"
              prefix="R$"
              v-model="company.Target"
              @change="company.Target = parseFloat(company.Target)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-1">
          <v-expansion-panel-header>
            {{ $t("video_options") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-text-field
              class="mt-0 pt-0"
              type="number"
              :label="$t('intro_video')"
              :hint="$tc('vimeo_hint', 1)"
              v-model="company.IntroVideo[selectedLanguage]"
            />
          </v-expansion-panel-content> </v-expansion-panel
        ><v-expansion-panel class="mt-1">
          <v-expansion-panel-header>
            {{ $t("overall") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              auto-grow
              outlined
              :label="$t('description')"
              rows="3"
              v-model="company.Description[selectedLanguage]"
            ></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="mt-1">
          <v-expansion-panel-header>
            {{ $t("photo_options") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-2 mb-2">
              <span>{{ $t("hero") }}</span>
              <div class="mt-1">
                <v-row align="center">
                  <v-img
                    class="ml-3"
                    v-if="company.Base64Hero != null"
                    :src="company.Base64Hero"
                    max-height="5rem"
                    max-width="5rem"
                  >
                  </v-img>
                  <v-img
                    class="ml-3"
                    v-else-if="company.Hero != null"
                    :src="gs.get_photo_path(company.Hero)"
                    max-height="5rem"
                    max-width="5rem"
                  >
                  </v-img>
                  <input
                    class="ml-4"
                    id="imageHeroInput"
                    name="imageHeroInput"
                    ref="imageHeroInput"
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="setImageHero"
                  />
                </v-row>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card-actions class="pl-0 dxa_modal_actions">
        <v-btn
          class="dxa_modal_btnSuccess"
          color="primary"
          min-width="100"
          :loading="loading"
          type="submit"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          class="dxa_modal_btnError"
          color="secondary"
          min-width="100"
          @click="$router.go(-1)"
          >{{ $t("go_back") }}</v-btn
        >
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import SelectBriefingLanguageComponent from "../components/SelectBriefingLanguageComponent";

export default {
  name: "ManageCompanyBasic",
  components: {
    SelectBriefingLanguageComponent,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    company_id: null,
    categories: [],
    company: {
      Description: {},
      Hero: null,
      Target: null,
      IntroVideo: null,
      Base64Hero: null,
    },
    selectedLanguage: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    company_prop: Object,
    roundBriefing: Boolean,
  },

  async created() {
    this.alert_msg = null;
    if (this.company_prop != null) {
      this.reload_company_dicts();
    }
  },

  methods: {
    checkBox_changed(s, list) {
      if (list.includes(s)) {
        var index = list.indexOf(s);
        list.splice(index, 1);
      } else {
        list.push(s);
      }
    },
    reload_company_dicts() {
      this.company = this.company_prop;

      if (this.company_prop.Description !== null) {
        if (this.isJson(this.company_prop.Description)) {
          this.company.Description = JSON.parse(this.company_prop.Description);
        } else {
          var text = this.company_prop.Description;
          this.company_prop.Description = {};
          this.company_prop.Description[this.selectedLanguage] = text;
          this.company.Description[this.selectedLanguage] =
            this.company_prop.Description[this.selectedLanguage];
        }
      } else {
        this.company.Description = {};
        this.company.Description[this.selectedLanguage] = "";
      }
      if (this.company_prop.IntroVideo !== null) {
        if (this.isJson(this.company_prop.IntroVideo)) {
          this.company.IntroVideo = JSON.parse(this.company_prop.IntroVideo);
        } else {
          var text = this.company_prop.IntroVideo;
          this.company_prop.IntroVideo = {};
          this.company_prop.IntroVideo[this.selectedLanguage] = text;
          this.company.IntroVideo[this.selectedLanguage] =
            this.company_prop.IntroVideo[this.selectedLanguage];
        }
      } else {
        this.company.IntroVideo = {};
        this.company.IntroVideo[this.selectedLanguage] = "";
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    setImageHero(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.company.Base64Hero = reader.result;
      };
    },
    setPresentationZip(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.company.Presentation = reader.result;
      };
    },
    addSlide(event) {
      this.company.Presentation = [];
      event.target.files.forEach((file) => {
        var slide = {};
        slide.Name = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          slide.File = reader.result;
          this.company.Presentation.push(slide);
        };
      });
    },
    setPptPresentation(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.company.Presentation = reader.result;
      };
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.submit();
      } else {
        this.loading = false;
      }
    },
    submit: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.error = null;

      var obj = JSON.parse(JSON.stringify(this.company));
      obj.IntroVideo = JSON.stringify(obj.IntroVideo);
      obj.Description = JSON.stringify(obj.Description);
      obj.Language = this.selectedLanguage;

      this.apiService
        .putRequest(
          `${
            this.roundBriefing
              ? "companybriefing/round-briefing"
              : "companybriefing/edit"
          }`,
          obj
        )
        .then((result) => {
          // this.$emit("saved", JSON.parse(result.message));
          this.$emit("alert", true, this.$t("saved"));
        })
        .catch((error) => {
          this.$emit("alert", false, error.body.message);
        });
      this.loading = false;
    },
  },
};
</script>
